.blog__post__heading {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
}

.blog__recommendation .blog__post__heading {
  text-align: left;
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 28px;
}

/* .blog__post__main__content {
  padding-top: 30px;
} */

.blog__breadcrumb span {
    text-transform: capitalize;
    display: flex;
    gap:5px;
}

.blog__post__wrappers.blog__recommendation {
  padding-bottom: 35px;
}

.blog__card__category {
    font-size: 14px;
    color: #EC4E1E;
    border: 1px solid #EC4E1E;
   font-weight: 500;
    padding: 2px 8px;
    border-radius: 6px;
    height: 26px;
    align-items: center;
    text-align: center;
    display: flex;
    font-family: "Rubik", sans-serif;
    letter-spacing: 1px;
    line-height: 14.22px;
}


.blogs__filter__buttons {
  display: flex;
  padding: 25px 0;
  gap: 10px;
  overflow: auto;
}

.blogs__filter__buttons .blog__btn {
      border: 1px solid #EC4E1E;
      border-radius: 6px;
      padding: 10px 15px;
      font-size: 14px;
      color: #EC4E1E;
      cursor: pointer;
  }
  
  .blogs__filter__buttons .blog__btn.active {
      background: #EC4E1E;
      color: #fff;
  }

  .blog__post__line {
    width: 100%;
  }
  
  .blog__post__line svg {
    width: 100%;
    height: auto; 
  }

  .blog__post__line line {
    vector-effect: non-scaling-stroke; 
  }
  
  .small-image img {
    width: 300px !important;
    height: 300px !important;
    object-fit: cover !important; /* Ensures image fills the container without distortion */
  }
   
  /* Medium Image */
  .medium-image img {
    width: 600px !important;
    height: 400px !important;
    object-fit: contain !important;
  }
   
  /* Large Image */
  .large-image img {
    width: 800px !important;
    height: 300px !important;
    object-fit: contain !important;
  }
  .blog__detail__content ul li{
    margin-left: 40px;
  }
@media(min-width:991px){
  .blogs__filter__buttons .blog__btn {
      border: 1px solid #EC4E1E;
      border-radius: 6px;
      padding: 10px 15px;
      font-size: 18px;
      color: #EC4E1E;
      cursor: pointer;
  }
  .blogs__filter__buttons {
      display: flex;
      padding: 35px 0;
      gap: 20px;
      overflow: auto;
  }

}

@media(min-width:991px){
    .blog__post__heading {
        font-weight: 600;
        font-size: 30px;
        text-align: center;
    }
    
    .blog__post__wrapper {
        padding: 50px 0;
    }
}


/* .blog__card__title {
    height: 65px;
} */

/* Blog Wrapper */
.blog__post__wrapper {
    padding: 20px 0;
    background-color: #fff;
  }
  

  /* Breadcrumb */
  .blog__breadcrumb {
    margin-top: 20px;
  }
  
  .blog__detail__heading h2{
    margin:0;
  }
  /* Blog Post Content */
  .blog__post__content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    /* padding:0 20px; */
  }
  
  .blog__card {
    background-color: #fff;
    border-radius: 10px;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 4px 4px 0px #00000040;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 500px;
  }
  
  .blog__card__image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .blog__card__content {
    padding: 15px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 5px;
  }
  
  .blog__card__header {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 10px; */
    align-items: center;
    gap:10px
  }
  
  .blog__card__author {
    font-size: 14px;
    color: #666;
  }
  
  .blog__card__category {
    font-size: 14px;
   
   
    padding: 2px 8px;
    border-radius: 6px;
    height: 26px;
    align-items: center;
    text-align: center;
    display: flex;
}

.blog__author__details img {
    width:40px;
    height: 40px;
    border-radius: 50%;
}

.blog__author__details {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
.blog__author__name{
  font-weight: 500;
  color: #000;

}
  
  .blog__card__meta {
    margin-bottom: 0px;
    margin: 0;
  }
  
  .blog__card__date {
    font-size: 12px;
    color: #999;
  }
  
  .blog__card__title {
    font-size: 18px;
    /* margin: 10px 0; */
    color: #333;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
  
  .blog__card__description {
    font-size: 14px;
    color: #777;
    margin-bottom: 13px;
    margin-top:0;
    /* height:100px; */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  
  .blog__card__read-more {
    background-color: #EC4E1E;
    color: #fff;
    border: none;
    padding: 7px 10px;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-start;
    letter-spacing: 1px;
  }
  
  .blog__card__read-more:hover {
    background-color: #EC4E1E;
  }
  
  /* blog details page css */

  .blog__detail__wrapper {
    padding: 0 20px;
    .breadcrumbs{
        a,span{
            font-size:14px;
        }

    }
      .breadcrumbs {
          padding: 0 0 20px 0;
      }
  }

  .breadcrumbs {
    display: flex;
    gap:5px;
  }

  .blog__breadcrumb span img {
    width: 13px;
    height: auto;
    transform: rotate(90deg);
  }
  

  .blog__detail__heading h1 {
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: normal;
    padding-bottom: 10px;
}

.blog__left__content{
  width:100%;
}

.blog__detail__content {
  padding: 20px 0;
}

.blog__detail__content img {
  border-radius: 15px;
  height: 100%;
  object-fit: inherit;
}
.blog__detail__author {
  display: flex;
  justify-content: left;
}

.blog__read__time {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: small;
}
.blog__read__time svg{
  height: 12px;
}
.single__line {
  width: 15px;
  background: #000;
  height: 1px;
  position: relative;
  top: 10px;
  margin: 0 5px;
}

.blog__detail__container {
  display: flex;
  justify-content: space-between;
  flex-direction:column;
  width:100%;
}

.blog__detail__content p {
  margin: 10px 0;
}

/* Background image */
.blog__detail__wrapper.page-width {
  padding-top: 20px;
}

.blog__right__content__wrapper:first-child {
  background-image: url(https://unfit-app-files.s3.ap-south-1.amazonaws.com/production/images/blog_post/1721639089.jpg);
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
}

.blog__right__content__wrapper.active svg path {
  stroke: #fff;
}

.blog__right__content__wrapper.active .single__line{
  background-color: #fff;
}

.blog__right__heading {
  font-weight: 500;
}

.blog__detail__container {
  display: flex;
  justify-content: space-between;
  flex-direction:row;
  width:100%;
  gap:40px;
}

.blog__right__image__wrapper {
  margin-top: 10px;
}


.blog__right__content__wrapper {
  box-shadow: 0px 0px 5px 0px #00000038;
  border-radius: 15px;
  padding: 20px;
  font-size:14px;
}
.blog__right__content__item p {
margin-bottom: 10px;
}

.blog__right__container__item {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* newletter css */
.blog__newsltter__heading {
  padding: 0px 0 15px;
}

.blog__newsletter__input {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.blog__newletter__submit {
  background: #121416;
  color: #fff;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  border-radius: 4px;

}

.blog__newsletter__input input {
  border: 1.5px solid #CECECE;
  border-radius: 4px;
  padding: 15px;
}

.blog__newsletter__checkbox input{
  border-radius: 0px;
}

.blog__newsletter__checkbox {
  display: flex;
  gap: 5px;
}

.blog__newsletter__checkbox p {
  line-height: 18px;
  padding: 10px 0;
  font-weight: 300;
  color: #A9A9A9;
}

@media (min-width: 769px) {
  .blog__post__content {
    grid-template-columns: 1fr 1fr;
  }
  .blog__author__details {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
  }

  .blog__detail__container.page-width {
      display: flex;
      justify-content: space-between;
      gap: 20px;
  }
  
 
  
  .blog__detail__content img {
      width: 100%;
  }
  
  .blog__right__content {
      /* width: 35%;
      max-width:350px; */
      width: 100%;
      max-width:fit-content;
      .blog__right__heading{
        padding-bottom: 15px;
      }
  }
  
  .blog__left__content{
    /* width:65%; */
    width:100%;
  }

  .blog__right__image__wrapper img {
      border-radius: 15px;
      height:100%;
      object-fit:cover
  }
  
  .blog__right__image__wrapper {
      width:100%;
      height:120px;
      /* padding:10px 0; */
  }
  .blog__detail__container{
    gap: 20px;
  }
  
}

.blogs__filter__buttons,.blog__post__line {
  
}
.blog__detail__content a{
  color: #EC4E1E;
}


@media (min-width: 1024px) {
  .blog__post__content {
    grid-template-columns: repeat(3, 1fr);
  }
  .blog__detail__wrapper {
      padding: 0 35px;
  }
  
  img.blog__card__image {
    height: 100%;
    max-height: 200px;
    min-height: 200px;
    object-fit: inherit;
}
  .blog__right__image__wrapper {
      width:100%;
      height:155px;
  }
  .blog__card__description {
    font-size: 14px;
    color: #777;
    margin-bottom: 13px;
    margin-top:0;
    overflow: hidden;
    height:65px;
  }
  .blog__author__details img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
  }
}
  


@media(max-width:768px){
  /* .blog__card__title {
    height: auto;
  } */
    .blog__detail__container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      gap: 40px;
  }
  .blog__right__content {
      width: 100%;
  }
  .blog__left__content {
    width: 100%;
  }

  .blog__post__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
  }
  .blog__right__content__wrapper{
    padding:15px;
  }
  .blog__right__container__item {
      display: flex;
      flex-direction: row;
      gap: 10px;
  }
  .blog__right__container__item{
    margin-top:10px;
  }

}

@media(max-width:600px){
  .blog__right__content__wrapper {
    font-size:12px;
  }
  .blog__card__description {
      font-size: 14px;
      color: #777;
      margin-bottom: 13px;
      margin-top: 0;
    
      overflow: hidden;
  }
}

@media(max-width:600px){
  .blog__post__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
  }
  .blog__right__container__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
    .blog__right__content__wrapper {
      height: 130px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size:14px;
  }
  .blog__card__header {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 10px; */
    align-items: baseline;
    flex-direction: column;
}

}

@media(max-width:500px){
    .blog__post__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
  }
    .blog__card__header {
      display: flex;
      justify-content: space-between;
      /* margin-bottom: 10px; */
      align-items: baseline;
      flex-direction: column;
  }
}

@media(max-width:426px){
  .blog__card__description {
    font-size: 14px;
    color: #777;
    margin-bottom: 13px;
    margin-top: 0;
    
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    align-items: center;
    flex-direction: row;
}

  .blog__post__content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}

@media(max-width:400px){
    .blog__card__header {
      display: flex;
      justify-content: space-between;
      /* margin-bottom: 10px; */
      align-items: center;
      flex-direction: row;
  }
}