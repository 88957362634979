/* src/components/filters/brandFilter.css */

.brand__filter__wrapper {
  display: flex;
  padding: 0 20px;
  gap: 30px;
}

.brand__filters {
  max-width: 220px;
  width: 30%;
}

.brand__filters h4 {
  margin-bottom: 10px;
}

.brand__category {
  border-top: 1.5px solid #DEE2E7;
}

.brand__filter__item.see__all a {
  color: #EC4E1E;
}

.brand__filters input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #BDBDBD;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
  padding: 0;
  background: #fff;
  margin-right: 10px;
  flex-shrink: 0;
  position: relative;
}

.brand__filters input[type="checkbox"]:checked {
  border-color: #EC4E1E;
  background: #EC4E1E;
}

.brand__filters input[type="checkbox"]:checked::before {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}


.brand__filters ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 15px;
  max-height: 185px;
  overflow: auto;
}

.brand__filters li {
  position: relative;
}

.brand__filter__item {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 6px 0;
  cursor: pointer;
}

.brand__filter__item,
.brand__filter__item a {
  font: var(--sup);
  color: #3D3D3D;
  transition: all 0.2s ease-in-out;
}

.brand__filter__item a {
  font-weight: 500;
}

.brand__filter__item:hover,
.brand__filter__item a:hover {
  color: var(--orange);
}

.brand__filters .brand__filter__item:hover input[type="checkbox"] {
  border-color: var(--orange);
}

.brand__product-list {
  width: 100%;
  padding: 0;
}

.brand__product-list-header {
  border: 0.6px solid #DEE2E7;
  padding: 10px;
  border-radius: 6px;
}

.brand__list.view-options div.active {
  background: #f4f4f4f5;
  padding: 5px 10px;
}

.brand__list .brand__list__view.active {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}


.brand__list .brand__grid__view.active {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.brand__count__name {
  font: var(--h6);
  color: var(--black);
  display: block;
}

.card_var_items:not(:last-child) {
  margin: 0 0 15px;
}

.card_var_body::-webkit-scrollbar {
  width: 5px;
}

.card_var_select {
  padding: 10px 5px 0;
}

.brand__product-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.brand__filter__wrapper .view-options button {
  background: none;
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin-left: 5px;
  cursor: pointer;
}

.brand__product-grid .product-card {
  padding-bottom: 20px;
}

.loadmore-btn {
  background: #ec4e1e;
  max-width: 100px;
  padding: 10px;
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
  margin: 0 auto 15px;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
}

/* .brand__product-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
} */
/* .brand__product-grid .product-card{
    padding-bottom:25px;
} */

/* .brand__product-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.brand__product-grid .product-card{
    padding-bottom:25px;
} */

.brand__paragraph {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

/* .brand__product-grid {
    display: flex;
    flex-wrap: wrap;
  }
  
  .brand__filter__wrapper .product-card {
    width: calc(31.333%);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  } */

/* .brand__filter__wrapper .product-card img {
  max-width: 100%;
  height: inherit;
} */

.brand__filter__wrapper .product-info {
  margin-top: 10px;
}

.brand__filter__wrapper .product-info h5 {
  font-size: 14px;
  margin-bottom: 10px;
}

.brand__filter__wrapper .product-info p {
  margin: 10px 0;
}

.brand__filter__wrapper .discounted {
  text-decoration: line-through;
  color: #888;
  margin-left: 10px;
}

.brand__filter__wrapper .discount {
  color: red;
  margin-left: 10px;
}

.brand__filter__wrapper .product-info button {
  background-color: #ff6f00;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
}

.brand__category h3 {
  font: var(--h5);
  padding: 12px 0;
}

details.brand__category__item svg {
  height: 15px;
  width: 15px;
  position: absolute;
  top: 15px;
  right: 5px;
  transform: scale(-1);
}

details.brand__category__item[open] svg {
  transform: scale(1);
}

.brand__category__item {
  position: relative;
}

details.brand__category__item svg path {
  stroke: #8B96A5;
}


.brand__category__item summary,
.brand__category__item summary:focus,
.brand__category__item summary:active,
.brand__category__item summary:hover,
.brand__categorys__filter li,
.brand__categorys__filter li:focus,
.brand__categorys__filter li:active,
.brand__categorys__filter li:hover {
  background-color: transparent;
  outline: none;
  /* Optionally remove outline on focus */
}

.brand__grid__view,
.brand__list__view {
  cursor: pointer;
}

.brand__list.view-options div {
  display: flex;
  padding: 5px 10px;
}

.brand__list.view-options {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
}

.brand__list.view-options svg path {
  width: 25px;
  height: 25px;
}


.brand__list__view svg {
  height: 20px;
}

.brand__grid__view svg {
  height: 20px;
}


main.brand__product-list .card-item {
  margin: 0;
}

.brand__product-list .brand__product-grid .card-wrapper,
.brand__product-list .brand__product-grid .product-card {
  padding: 0;
}

.brand__product-list .brand__product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  row-gap: 25px;
  margin-bottom: 40px;
}

.brand__product-list .page-width{
  padding: 0px;
}
.brand__product-list .brand__product-grid .product-card {
  width: calc(20% - 11px);
}

.brand__filter__item.see__all {
  color: var(--orange);
}

.brand__product-list .brand__product-grid.no_filter .product-card {
  width: calc(20% - 11px);
}

@media(min-width:768px) {

  .brand__product-list-header {
    border: 0.6px solid #DEE2E7;
    padding: 10px 20px;
    border-radius: 6px;
  }
}

@media(max-width:990px) {
  .brand__product-list .brand__product-grid {
    gap: 10px;
  }

  .brand__product-list .brand__product-grid .product-card {
    width: calc(33.33% - 7px);
  }

  .brand__product-list .brand__product-grid.no_filter .product-card {
    width: calc(25% - 10px);
  }
}

@media(max-width:850px) {
  .brand__product-list .brand__product-grid.no_filter .product-card {
    width: calc(33.33% - 7px);
  }
}

@media(max-width:520px) {
  .brand__product-list .brand__product-grid {
    gap: 20px 10px;
  }

  .brand__product-list .brand__product-grid .product-card,
  .brand__product-list .brand__product-grid.no_filter .product-card {
    width: calc(50% - 5px);
  }
}