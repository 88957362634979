/* productCard.css */

.product-card {
  width: 100%;
  box-sizing: border-box;
}

.collection__wrapper .product-card {
  padding: 25px 0 0;
}

.card-wrapper {
  padding: 0 10px;
  height: 100%;
}

.card-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 4px 1px #00000033;
  height: 100%;
  background: var(--white);
}

.card-item a {
  display: block;
  width: 100%;
}

.slider_container .slick-list .slick-track {
  padding: 10px 0;
}

.card-image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
}

.card-image {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 0 0 95%;
}

.card-image img {
  width: 80%;
  height: 80%;
  position: absolute;
  object-fit: contain;
  margin: auto;
  inset: 0;
}

.card-wrapper .card-item .product__discount {
  position: absolute;
  background: var(--orange);
  padding: 5px 11px;
  top: 0px;
  left: 0px;
  color: #fff;
  letter-spacing: 0.6px;
  border-radius: 0px 0px 10px 0px;
  font: var(--sup);
  font-weight: 400;
  width: fit-content;
  z-index: 1;
}
.wishList-button{
  position: absolute;
  
  height: 20px;
  top: 5px;
  right: 5px;

 
 
  width: fit-content;
}

.card-content {
  padding: 0 12px 20px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card-content div {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font: var(--h6);
  color: #262626;
  font-weight: 400;
  letter-spacing: 0.6px;
}

.card-content p {
  font-size: 14px;
  color: #555;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.product_btns {
  margin-top: 15px;
}

.product_btns_disabled {
  background: #7a7776;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  /* Added margin-top */
}

.product_atc_btn img {
  height: 18px;
  width: 18px;
}

.product_atc_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  cursor: pointer;
  background: var(--orange);
  font: var(--h6);
  font-weight: 400;
  padding: 8px 10px;
  border-radius: 7px;
  color: var(--white);
}

.product_price {
  margin: 10px 0 0;
}

.product_price .price {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  /* Added margin-top */
}

.actual_price {
  color: #262626;
  font: var(--h6) !important;
  letter-spacing: 1px;
}

.compare_price,
.product__discounts {
  font: var(--sup);
  font-weight: 400;
}

.compared_price {
  color: #8e8e8e;
}

.hm__product-container.sports__supplements {
  margin-top: 20px;
}

.extra__product__card-item p {
  margin: 5px 0;
  text-align: center;
}

.discount_percentage {
  font-size: 14px;
  color: #4fb400;
}

.slider_container .slick-slide {
  height: auto;
}

.slider_container .slick-track {
  display: flex;
}

.slider_container .slick-slide>div,
.slider_container .slick-slide .product-card {
  height: 100%;
}

.card-content>a,
.card-content .product_title {
  flex: 1;
}

.product_atc_btn {
  display: flex !important;
  justify-content: center;
  div{
   color: #fff !important;
  }
}

/* Extra product Card slider css */
.extra__product__card {
  display: flex;
}

/* 
.extra__product__card .slick-prev,
.extra__product__card .slick-next {
  display: none !important;
} */

.extra__product__card-item {
  width: 100%;
  margin: 0 10px;
  padding: 10px 0;
}

.extra__product__card-item .extra__product__card-inner {
  padding: 10px;
  border-radius: 6px;
  height: 100%;
}

.bolt_nutri_slide {
  width: 100%;
  height: 100%;
}

.extra__card__item {
  margin: 0 auto;
  display: flex;
  width: 100%;
}

.buy__button.buy__now__btn {
  background: #fff;
  color: #000;
  border-radius: 6px;
  gap: 0;
  padding: 10px;
  display: flex;
  justify-content: center;
}

/* .extra__product__card-item img {
  width: 90%;
  height: auto;
  margin: 0 auto;
  mix-blend-mode: multiply;
} */

.extra__product__card-item img {
  width: 20%;
  height: 100%;
  max-height: 85px;
  min-height:fit-content;
  margin: 0 0;
  mix-blend-mode: multiply;
}

.hm__prod__slider {
  display: block;
}

.hm__product-container.hm__prod__slider.page-width {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hm__prod__mobile__arrow {
  display: none;
}

.bolt_nutritions {
  

}

.brand__product-grid .card-item .card-image {
  padding: 0 0 100%;
}

.extra__product__card-inner .card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
}

@media (min-width: 768px) {
  .hm__product-container.section_space.bolt_nutritions.hm__prod__slider 
       .card-content .product_title {
          flex: 1 1;
          min-height: 65px;
      }
}

@media (max-width: 767px) {
  .hm__prod__slider-title {
    font-size: 16px;
  }

  .hm__prod__slider-title,
  .offer__banner-heading.ban__banner-heading {
    display: flex;
    justify-content: space-between;
  }

  .hm__prod__mobile__arrow {
    display: block;
  }
}

@media (max-width: 500px) {
  .extra__product__card .card {
    width: auto;
    margin: 30px 0px 25px 5px;
  }
  .card-wrapper {
    padding: 0 5px;
    height: 100%;
  }
}

@media (max-width: 320px) {
  .hm__product-container {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
  }

  .product_atc_btn .btn_text {
    font-size: 15px;
  }

  .card-content div{
    font-weight: 400;
  }

  .product_title div {
    font-weight: 400;
  }

  .product_btns {
    padding: 0px;

  }

  .product_atc_btn {
    gap: 7px;
  }
}