/* Header.css */


.icon-hamburger {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  padding: 15px 0;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

img {
  width: 100%;
  height: auto;
}

.navbar ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar ul li {
  margin-right: 1rem;
}

.navbar ul li a {
  color: var(--black);
  text-decoration: none;
  font: var(--h5);
}

.navbar ul li a:hover {
  text-decoration: underline;
}

.header__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  width: 100%;
  max-width: 160px;
  height: auto;
}

input.search-input {
  padding: 10px 40px;
  border: 1px solid #686868;
  border-radius: 7px;
  width: 100%;
  font: var(--sup);
}

.search-input::-webkit-input-placeholder {
  font: var(--sup);
  color: #5A5A5A;
  font-weight: 300;
}

.search-input::-moz-placeholder {
  font: var(--sup);
  color: #5A5A5A;
  font-weight: 300;
}

.search-input:-ms-input-placeholder {
  font: var(--sup);
  font-weight: 300;
  color: #5A5A5A;
}

.search-input:-moz-placeholder {
  font: var(--sup);
  font-weight: 300;
  color: #5A5A5A;
}

/* Initial header style */
.header__wrapper {
  position: relative;
  transition: all 0.3s ease;
  padding:10px 0;
  background:#fff;
}

/* Sticky header style */
.header__wrapper.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; 
}
  /* .header__wrapper.sticky {
  } */

  .main-nav.sticky {
    position: fixed;
    top: 83px;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #0003;
    transition: top 0.3s ease-in-out; /* Smooth transition */
}


header .header__searchbar {
  max-width: 450px;
  display: none;
  width: 100%;
  margin-right: 20px;
}

.mega-menu-content .navbar {
  display: none;
}

.user__name {
  display: flex;
  align-items: center;
  gap: 5px;
}

.navbar ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar li {
  margin-right: 15px;
  /* Adjust spacing as needed */
}

.navbar a {
  text-decoration: none;
  color: #000;
}

.header__icons_inner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.header__center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.icon-search,
.header__account img,
.header__bag img,
.icon-hamburger img {
  width: 24px;
  height: 24px;
}

.header__searchbar {
  position: relative;
}

/* search bar */

header .header__searchbar {
  display: none;
}

.hamburger__menu {
  display: block;
  flex: 1;
}

.header__searchbar img.icon-search {
  position: absolute;
  right: unset;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
}

.login__btn__mob {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.loginWrapper {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.line__svg {
  padding: 0 15px 10px 15px;
  max-width: 300px;
}

.menuDrawer {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  border-radius: 0 10px 10px 0;
  box-shadow: 4px 4px 9px 0px #00000040;

}

.main__container {
  z-index: 2;
}

.minicart__overlay {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 300ms ease-in-out;
}

body.minicart-active .minicart__overlay {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

body.minicart-active .minicart__modal {
  right: 0;
}

.drawerItem {
  padding: 10px 30px;
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: 0.3s;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.96px;
  text-align: left;
  align-items: center;
  cursor: pointer;
}

div#menuDrawer .nav__menu {
  font: var(--sup);
  color: #1C1C1C;
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  text-align: left;
}

.main__nav-container li .left__arrow__mobile__header {
  transform: rotate(-90deg);
}

.menuDrawer .main__nav-container {
  max-width: 270px;
}

.main__nav-container>li {
  padding: 11px 0;
}

.menuDrawer .drawerItem:hover {
  background-color: var(--orange);
}

.menuDrawer .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  background-color: white;
}

.leftArrow {
  height: 10px;
  width: 10px;
  transform: rotate(90deg);
}

.close {
  height: 20px;
  width: 20px;
}

.drawerTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  padding: 20px 15px;
  display: flex;
  gap: 15px;
  align-items: center;
}

.drawerTitle>img {
  width: 15px;
}

.drawerTitle .close_icon {
  margin: 0 0 0 auto;
}

.close_icon img {
  width: 20px;
}

.imageTurn {
  transform: rotate(180deg);
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.loginWrapper {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
}

.left__arrow__mobile__header {
  display: block;
}



.header__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.itemsCount {
  background-color: var(--orange);
  color: #ffffff;
  border-radius: 50%;
  position: absolute;
  width: 15px;
  height: 15px;
  font-size: 10px;
  line-height: 17px;
  right: -3px;
  bottom: 1px;
  text-align: center;
}

.header__announcement-bar {
  background: #ec4e1e;
  color:WHITE;
  text-align: center;
}

.header__announcement-container {
  padding: 5px 0;
}

.header__announcement-wrapper {
  display: flex;
  justify-content: center;
  gap: 5px;
  font: var(--sup);
  letter-spacing: 1px;
  font-weight: 400;

}
.get-app {
  --offset: 3px;
  position: relative;
  background: white;
  width: 100px;
  height: 40px;
  max-width: 100%;
  overflow: hidden;
  border-radius: 50px;
  color: #ec4e1e;
  display: flex;

  
}
.get-app::before { 
  content: '';
  background: conic-gradient(transparent 200deg,#ec4e1e,transparent);
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  aspect-ratio: 1;
  width: 100%;
  animation: rotate 2s linear infinite;

}
.get-app::after { 
  content: '';
  background: inherit;
  border-radius: inherit;
  position: absolute;
  inset: var(--offset);
  height: calc(100% - 2 * var(--offset));
  width: calc(100% - 2 * var(--offset));


}
.get-app button{
  position: absolute;
  inset: 0;
  z-index:10 ;
/* height: 100%;
width: 100%; */
font-size: 0.3rem;
font-weight: 500px;
background: white;
color:#ec4e1e;
border: none;
margin: 3px;
gap: 3px;
padding: 3px 4px;
border-radius: 50px;
display: flex;
align-items: center;
justify-content: center;
}
.get-app button img{
  width: 20px;
  height: 110%;
  /* background-image: url(./../../../assests/image/app-icon.gif); */
  
}
.get-app p{
  font-weight: 550;
  font-size: small
}
@keyframes rotate {
 from {
  transform: translate(-50%,-50%) scale(1.4) rotate(0turn);

  }
  to {
    transform: translate(-50%,-50%) scale(1.4) rotate(1turn);

  }
}

.modal-container{
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  /* Stay in place */
  z-index: 11;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */

  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.modal-wrapper{
  background-color: #fefefe;
  margin: 15% auto;

  border: 1px solid #888;
  width: 80%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
 
  height: 100%;
  max-height:500px;
  min-height:340px;
  max-width: 700px;
  border-radius: 20px;
  border: 1px solid #BCBBBB;
  overflow-x: auto;
  flex-direction: row-reverse;
  
}
.download-btns-wrapper{
  display: flex;
  height: 55px; 
  gap: 5px;
 
 

}
.scanner{
  width: 100%;
}

.close-icon{
  width: 7%;
  height: 100%;
  display: flex;
  
  justify-content: center;

  padding: 10px;
}

.close-icon img{
width: 30px;
height: 25px;
cursor: pointer;


}
.spinner-contanier{
  width: 55%;
  
  height: 100%;
overflow: hidden;
}
.spinner-background{
  background-image: url('./../../../assests/image/spinnerbackground.png');
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  left: 70px;

}
.spinner{
  position: relative;
  left: -288px;
  top: 15px;
  height: 95%;
  object-fit: cover;
  width: 140% !important;
  max-width: max-content !important;
}
.content{
  height: 100%;
  width: 45%;
  padding: 10px 0px 0px 0px;
  display: flex;
  justify-content: space-evenly ;
  align-items: center;
  flex-direction: column;

}
.download-btns{
  width:  50%;
}
.download-btns img{
 
  height:100%;

}
.content h1{
  font-size: 23px;
}



@media screen and (min-width: 991px) {
  .navbar {
    display: block;
  }

  .left__arrow__mobile__header {
    display: none;
  }

  header .header__searchbar {
    display: block;
  }

  .header__searchbar {
    display: none;
  }

  .hamburger__menu {
    display: none;
  }

  .header__icons {
    gap: 20px;
  }

  .menuDrawer {
    display: none;

  }

}

@media(min-width:768px) {
  .header__announcement-wrapper {
    flex-direction: row;
  }

}

@media(max-width:990px) {
  .header__searchbar {
    max-width: 800px;
    margin: 15px auto 0;
    padding: 0 8px;
    display: block;
  }

  .header__announcement-wrapper {
    font-size: 12px;
  }

 @media (max-width: 990px) {
  .header__wrapper {
      box-shadow: 0 2px 4px 0 #0003;
  }
}

  .logo img {
    max-width: 120px;
  }

  .main-nav .page-width {
    padding: 0;
  }

  .menuDrawer .main_menuDrawer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .menuDrawer .drawerItem:hover {
    color: #fff;
  }

  .menuDrawer .drawerItem:hover img {
    filter: invert(1);
    -webkit-filter: invert(1);
  }

  div#menuDrawer .active .nav__menu {
    color: var(--orange);
  }

  .drawerItem {
    padding: 15px 10px;
  }
}


@media(max-width:768px) {
  .header__announcement-wrapper {
    flex-direction: column;
  }
  .get-app{
    display: none;
  }
}