.footer__wrapper {
    color: #fff;
}

footer h5 {
    padding-bottom: 20px;
}

footer p.footer__heading__para {
    padding-bottom: 20px;
}
footer p.footer__heading__para {
    font: var(--h5);
    margin: 0;
}

footer p.footer__heading__para {
    padding: 20px 0;
}
footer p.footer__heading__para {
    font: var(--h5);
    margin: 0;
    color: #000;
}
.footer__logo-items p {
    letter-spacing: 0.5px;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
}

/* .footer__about-us {
    max-width: 335px;
    width: 50%;
} */

.footer__about-us p {
    font: var(--sup);
    letter-spacing: 1px;
    color: #000;
}

footer li {
    padding-bottom: 15px;
    display: flex;
    gap: 5px;
    align-items: flex-start;
    font-size: 14px;
    letter-spacing: 0.5px;
}

.footer__about__content {
    font-size: 14px;
    letter-spacing: 0.5px;
}
.footer__subscribe-item {
    max-width: 370px;
    width: 100%;
}

.footer__container {
    display: flex;
    flex-wrap: wrap;
}

.logo__item {
    margin: 0 0 30px;
}

.footer__container .playstore__item img,
.apple__item img {
    max-width: 170px;
    width: 100%;
}

.footer__logoes {
    display: flex;
    justify-content: center;
    gap: 13px;
    flex-direction: column;
}

.footer__logo-items p {
    color:#000;
}

.footer__logo-items {
    display: flex;
    width: 25%;
    flex-direction: column;
    justify-content: center;
    padding-left: 0px;
}

.footer__copyright.caption {
    color: #fff;
    border-top: 1px solid #fff;
    padding: 15px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

footer.orange__background li a:hover{
    text-decoration:underline;
    color:#dfdfdf !important;
}

small.copyright__content__img img {
    max-width: 100%;
    height: 35px;
}

.contact_us-content li a {
    text-decoration: underline;
}

.contact_us-content li a:hover {
    color: var(--orange);
}

.footer__copyright__menu ul {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.footer__copyright__menu li a {
    font-size: 14px;
    text-decoration: underline;
    color: #000 !important;
}

.footer__copyright__menu li {
    padding: 0;
}

.copyright__content {
    font-size: 11px;
    line-height: 1.4;
    display: block;
    text-align: center;
    letter-spacing: 1px;;
}

.copyright__content a {
    color: #fff;
}

.copyright__content a:hover {
    color: #dfdfdf;
    text-decoration: underline;
}

.footer__wrapper li a {
    font: var(--sup);
    color: #000;
    transition: all 0.2s ease-in-out;
}

.footer__wrapper li a:hover {
    color: var(--orange);
}

.contact_us-content a:hover {
    text-decoration: underline;
}

.footer__about-us {
    max-width: 50%;
}

.footer__subscribe-item {
    max-width: fit-content;
    width: 100%;
}

.footer_social {
    margin: 15px 0 15px;
}

.footer_social ul {
    display: flex;
    align-items: center;
    gap: 15px;
}

.footer_social ul li {
    padding: 0 0 0;
}

.footer_social ul img {
    height: 24px;
    width: 24px;
}

.footer_news h5 {
    padding: 0 0 15px;
}

.footer_news p {
    font-size: 16px;
}

.newslatter {
    margin: 25px 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.input_field {
    flex: 1;
}

.input_btn button {
    background: var(--orange);
    border: none;
    padding: 8px 0;
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    width: 100%;
    border-radius: 4px;
}

.input_btn {
    max-width: 110px;
    width: 100%;
}

.icon{
    width: 20px;
    
}


.footer__container > div {
    margin-right: 20px;
}
.footer__container:last-child > div{
    margin:0;
}
.logo__item img {
    max-width: 180px;
    width: 100%;
    margin: 0 auto;
}

small.copyright__content img {
    max-width: 250px;
}


footer.orange__background {
    background: #EC4E1E;
    color: #fff;
}

footer.orange__background li, footer.orange__background a, .footer__heading__para{
    background: #EC4E1E;
    color: #fff !important;
}

.footer__container p {
    color: #fff;
}

.mobile__footer__hide.orange {
    display: block;
    text-align: center;
    color: #ec4e1e;
    padding: 10px;
    font-size: 16px;
    letter-spacing: 0.5px;
}

@media(min-width:768px) {
    .footer__container {
        flex-direction: row;
    }
    .footer__logo-items p{
        text-align: left;
    }
    .footer__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 40px 0;
        gap: 20px;
    }
    .footer__about__content {
        font-family: 'Rubik';
        letter-spacing: 0.5px;
  }
  .copyright__content {
    font-size: 12px;
    line-height: 1.4;
    display: block;
    text-align: center;
    letter-spacing: 1px;;
}
  .footer__copyright.caption {
    flex-direction: row;
    gap:0px;
}
.mobile__footer__hide.orange {
    display: none;
}
}

@media(min-width:768px){
    .logo__item img {
        max-width: 180px;
        width: 100%;
        margin: unset;
    }
    .footer__about-us {
        max-width: 25%;
    }
    footer.orange__background.hidden {
        display: block;
    }
}

@media(min-width:1024px) {
    .footer__container {
        flex-wrap: wrap;
        gap: 0px;
    }
}

@media(max-width:990px){
    .footer__logo-items{
        width:45%;
    }
    .footer_social ul{
        align-items: center;
        justify-content: center;
    }
    /* .footer__about__content {
        font-size: 12px;
        letter-spacing: 0.5px;
        font-family: 'Rubik';
  } */
}