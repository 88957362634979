  .product-detail {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 0 0;
    align-items: flex-start;
  }
  .breadcrumb-product-name {
    display: inline-block !important;
    max-width: 150px; /* Max width constraint */
    white-space: nowrap; /* Prevent text from wrapping to a new line */
    overflow: hidden; /* Hide overflowed text */
    text-overflow: ellipsis; /* Show "..." when text overflows */
    vertical-align: bottom;
  }

  .product-detail__images {
    flex: 1;
  }

  .isNotDefaultVariant.hide {
    display: none;
  }

  .product__thumbnails .thumbnail img {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    object-fit: contain;
  }

  input.quantity__input {
    width: 50px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
  }

  .overlay {
    position: absolute;
    /* Position it absolutely within the relative container */
    background: White;
    /* Semi-transparent background color (adjust as needed) */
    color: #ff1a1a;
    /* Text color */
    display: flex;
    /* Flexbox to center content */
    height: 10%;
    width: 80%;
    align-items: center;
    /* Center vertically */
    justify-content: center;
    /* Center horizontally */
    text-align: center;
    /* Center text */
    box-shadow: 0px 0px 4px 0px #00000040;
    border-radius: 9px;
  }

  .product_atc_btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.6;
  }

  .product-detail__images img {
    width: 100%;
    height: auto;
  }

  .product-detail__info {
    flex: 2;
  }

  .product-detail__info h1 {
    margin-bottom: 10px;
  }

  .product-detail__info p {
    margin-bottom: 10px;
  }

  .btn-primary {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }

  .btn-primary:hover {
    background-color: #0056b3;
  }

  .product-image {
    width: 55%;
  }

  .product__media__des img {
    max-width: 900px;
    display: flex;
    margin: 10px auto 0;
    border-radius: 6px;
    height: 400px;
    object-fit: contain;
  }

  .heading.product__desc {
    font-weight: 600;
    letter-spacing: 0.6px;
    font-size: 24px;
  }

  .store__name {
    padding: 10px 0 15px;
    border-bottom-width: 1.5px;
    border-bottom-style: dashed;
    border-bottom-color: #aaaaaa;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .store__name span {
    color: var(--black);
  }

  img.review__icon {
    width: 130px;
  }

  .store__name a img {
    height: 13px;
    width: 15px;
    transform: rotate(90deg);
    align-items: center;
  }

  .store__name span {
    margin-right: 5px;
  }

  .product-info {
    width: 45%;
    padding-left: 40px;
  }

  .quantity__selector__container {
    padding-bottom: 10px;
  }

  .thumbnail img {
    height: 100%;
    align-items: center;
  }

  .prod__img {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-image: initial;
    padding: 0 0 80%;
    border-radius: 15px;
    background: rgb(247, 247, 247);
    width: 100%;
  }

  .prod__img img {
    width: 800%;
    height: 80%;
    object-fit: contain;
    position: absolute;
    inset: 0;
    margin: auto;
  }
  .prod__img video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    inset: 0;
    margin: auto;
    z-index: 2;
  }
  .video-thumbnail img {
    display: block;
  }

  .product__thumbnails {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 35px;
    border: 2.5px solid #f5f5f5;
    box-shadow: 0px 0px 4px 0px #bdbdbd40;
    border-radius: 12px;
  }

  .imgkey {
    width: 50px;
    height: 50px;
    border: 1px solid #ddd;
  }

  /* .product__thumbnails .slick-arrow {
    display: none!important;
  } */

  .main__product__title h1 {
    font-size: 24px;
    font-weight: 400;
  }

  .store__name a {
    display: flex;
    align-items: center;
    font: var(--h6);
    font-weight: 400;
  }

  .price_section {
    padding: 20px 0;
    border-bottom: 3px solid #ececec;
    margin: 0 0 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
  }

  .offer_Price_App {
    width: max-content;

    background-color: #4fb400;
    color: white;
    border-radius: 7px;
    border: none;
    display: flex;
    padding: 5px 10px;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
  }
  .offer_Price_App .icon {
    width: 25px;
  }
  .offer_Price_App_img {
    width: 8px;
    height: 12px;
  }
  .offer_Price_App p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: left;
  }

  .pdp_price_main {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .slick-slider.mobile__slider .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pdp_rv_stars {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    margin: 0 0 30px;
    flex-wrap: wrap;
  }

  .pdp_rv_stars img {
    width: 30px;
    cursor: pointer;
  }

  .product_review {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    margin: 5px 0 0;
  }

  .pdp_review_count {
    color: #737373;
    font: var(--sup);
    text-decoration: underline;
  }

  .option__name {
    color: #acacac;
    font: var(--sup);
    display: block;
  }

  /* .flavour__option__item {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAATYSURBVHgB7ZyLkds2EIY/p4LrwNtBroNDKsh1YKSCXAdiKrA7oDqwU4GUCnypQHIFSQcOVyRjWdaCIAk+ROqb2dENCeLx3xJv4g3T8lCYK0wK+7n6leqeXIT9t7Dj2e+XwvaFvVbXVkEt2PvCDoV9TWSfC8sLe2ahOMoC/kM60UKWV2neNOptG9J6WlvTtD03Ri3cWN62KCG1DtLMzkW4wYV8QxqE7vXOkbI1/bv6+/Xs+mUaD5U9FvZUXXukPdvC/riSxiS8o93rqmFVbE8pRl+kimvbMh/JvbEL2h2JzfCusBfSiGahcXvKbk1svt4zAUJ8JlU4x/i4Ku2YPGpZhJEQ4hqKA/Poi3ni8ysMjERmJmN+ZEwsotAsnt7v0iqOhRBXBiExEpHwlmEbiFRoHj8ysohNDUbG7ZHR3LAkoamrknG7ZAzcxfEsV7yajHAZ39ERIVzvZSyHDLucOroROpAHIv3I8thil3dHSzzhFuoWWtu2aJlCb1yr2e5QRMJy0T5sb8fxrKPes8joWX7L+w6sB0sDbVCCXuix1fesB0dHL7SU37E+tMyt3kSHrbpjfTha6pEbgZONCW8Qywvza4Hvdd+P6BJEVGPyHBtwZWjZrYUqpwF+qgL+akTwiRVt3LmClv1P495pZFILaM0i/8WdvXH9f6dTNx1y2OYoJx+0QvYMz++J0wvpc6reHMO1vp7IFiwROT0nAQwOYE8wZMbNT/RnR4tuQE+smfMd/dkacb9oHSjGQ3v6I8Z1T7mDKxUa14txL0Uv4tW4rrtqTS9x9CfDrj/UUoi4aUgjhbdb3byTd1srbinWdx9oXtHrI2KTeJ9Jt4HpWvwHvWl1FN+ShqFEHEs8RQgIaGUgJalFHFO8GlOnMQRUUok4hXgwAwGVviI2iXdguLH7LARUmla/LBFjxBOGw9TJKsxbhkNoJ+LU4omRrjbAkwhYZypGxKnFU6zlztNwd2fcTDGGbELo91nEGOIpzkh/p0O5L8ZDwvAcC/uFbp8bHHs82xZrUHFUAYPjvBE40l6ILs/04cm4fnI+a5x3YFyEGW0Gv8Dqejm92ThhOCJCWMQpxBMi9LEy7RkfMfIzhXiKJ9AC12si1sLJE+NzpKzf9mfX9oxb551jafBd2+GwO4pTLmtq2sJ0hJY1n2MDvrBePHb99wNbI+CO9WK1vvm1wA5bbcf6cHTQ48DdC2u0zFZXyiTj7oWKx9bBhx4MNSYH1oP1JkZpkGGrn7F8NnT0vpqmGeMUy51zRSA4jIzmuSGiNX5o42nJLhBZzvIIfUPcqbxC+BiRDctByxJ644SO+EDESxExJF6nV/eSDyxXxCbxPpCIITcITUWTeEk/7xCap9u1Er6VQydywmXpVe9ZCBMdGZIQ7cNOWgYhbuFnw/zQPDXlexQHEOJXzzzT45jhap9wP3wsCU1dnMtMeoY//k6XIGKF+0rCrkpXPO32t5wfwCj0pz4zMKf9QZCenqQ8AjSj2yE1ujx4pPysrD5Uuz5s+zIN5ZFvB3c7uv0T9oX9xkyOAD3HM/9DaMfYddYbz7yE1Nc14wan4TzTH8SdsYD5S0f4aKXU3paz4EUwrYO2tDtpN8bTtDviGNnbUrXCXakP1XaUe7KFb/thLoU4nv2q1Qd375nwq/r/AKpm4VdzsIgYAAAAAElFTkSuQmCC")
      white no-repeat 98.5% !important;
    /* !important used for overriding all other customisations */
    /* background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAATYSURBVHgB7ZyLkds2EIY/p4LrwNtBroNDKsh1YKSCXAdiKrA7oDqwU4GUCnypQHIFSQcOVyRjWdaCIAk+ROqb2dENCeLx3xJv4g3T8lCYK0wK+7n6leqeXIT9t7Dj2e+XwvaFvVbXVkEt2PvCDoV9TWSfC8sLe2ahOMoC/kM60UKWV2neNOptG9J6WlvTtD03Ri3cWN62KCG1DtLMzkW4wYV8QxqE7vXOkbI1/bv6+/Xs+mUaD5U9FvZUXXukPdvC/riSxiS8o93rqmFVbE8pRl+kimvbMh/JvbEL2h2JzfCusBfSiGahcXvKbk1svt4zAUJ8JlU4x/i4Ku2YPGpZhJEQ4hqKA/Poi3ni8ysMjERmJmN+ZEwsotAsnt7v0iqOhRBXBiExEpHwlmEbiFRoHj8ysohNDUbG7ZHR3LAkoamrknG7ZAzcxfEsV7yajHAZ39ERIVzvZSyHDLucOroROpAHIv3I8thil3dHSzzhFuoWWtu2aJlCb1yr2e5QRMJy0T5sb8fxrKPes8joWX7L+w6sB0sDbVCCXuix1fesB0dHL7SU37E+tMyt3kSHrbpjfTha6pEbgZONCW8Qywvza4Hvdd+P6BJEVGPyHBtwZWjZrYUqpwF+qgL+akTwiRVt3LmClv1P495pZFILaM0i/8WdvXH9f6dTNx1y2OYoJx+0QvYMz++J0wvpc6reHMO1vp7IFiwROT0nAQwOYE8wZMbNT/RnR4tuQE+smfMd/dkacb9oHSjGQ3v6I8Z1T7mDKxUa14txL0Uv4tW4rrtqTS9x9CfDrj/UUoi4aUgjhbdb3byTd1srbinWdx9oXtHrI2KTeJ9Jt4HpWvwHvWl1FN+ShqFEHEs8RQgIaGUgJalFHFO8GlOnMQRUUok4hXgwAwGVviI2iXdguLH7LARUmla/LBFjxBOGw9TJKsxbhkNoJ+LU4omRrjbAkwhYZypGxKnFU6zlztNwd2fcTDGGbELo91nEGOIpzkh/p0O5L8ZDwvAcC/uFbp8bHHs82xZrUHFUAYPjvBE40l6ILs/04cm4fnI+a5x3YFyEGW0Gv8Dqejm92ThhOCJCWMQpxBMi9LEy7RkfMfIzhXiKJ9AC12si1sLJE+NzpKzf9mfX9oxb551jafBd2+GwO4pTLmtq2sJ0hJY1n2MDvrBePHb99wNbI+CO9WK1vvm1wA5bbcf6cHTQ48DdC2u0zFZXyiTj7oWKx9bBhx4MNSYH1oP1JkZpkGGrn7F8NnT0vpqmGeMUy51zRSA4jIzmuSGiNX5o42nJLhBZzvIIfUPcqbxC+BiRDctByxJ644SO+EDESxExJF6nV/eSDyxXxCbxPpCIITcITUWTeEk/7xCap9u1Er6VQydywmXpVe9ZCBMdGZIQ7cNOWgYhbuFnw/zQPDXlexQHEOJXzzzT45jhap9wP3wsCU1dnMtMeoY//k6XIGKF+0rCrkpXPO32t5wfwCj0pz4zMKf9QZCenqQ8AjSj2yE1ujx4pPysrD5Uuz5s+zIN5ZFvB3c7uv0T9oX9xkyOAD3HM/9DaMfYddYbz7yE1Nc14wan4TzTH8SdsYD5S0f4aKXU3paz4EUwrYO2tDtpN8bTtDviGNnbUrXCXakP1XaUe7KFb/thLoU4nv2q1Qd375nwq/r/AKpm4VdzsIgYAAAAAElFTkSuQmCC")
      white no-repeat calc(100% - 10px) !important; */
     /* Better placement regardless of input width */
    /* margin: 0;
    padding: 11.33px;
    letter-spacing: 1px;
    border: 0.6px solid #8d8d8d;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
    max-width: 400px;
    background-size: 20px !important;
  } */ 

  .product__description-item details.product__desc summary svg {
    transform: rotate(180deg);
  }

  details.product__desc[open] summary svg {
    transform: rotate(360deg);
  }

  /*For IE*/
  select::-ms-expand {
    display: none;
  }

  .main__product__price .actual__price {
    font: var(--h4);
  }

  .compare__price {
    display: flex;
    font: var(--sup);
    gap: 5px;
    margin: 5px 0 0;
    color: #737373;
  }

  .slick-slider.product__thumbnails .slick-arrow {
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    width: 15px;
    height: 15px;
  }

  .slick-slider.product__thumbnails .slick-arrow.slick-prev {
    top: 15px;
    transform: rotate(45deg);
    border-left: 2px solid var(--black);
    border-top: 2px solid var(--black);
  }

  .slick-slider.product__thumbnails .slick-arrow.slick-next {
    bottom: 20px;
    top: unset;
    border-left: 2px solid var(--black);
    border-bottom: 2px solid var(--black);
    transform: rotate(-45deg);
  }

  /* .product__thumbnails button.slick-arrow.slick-prev {
    content: '→';
    background-image: url('http://localhost:3000/static/media/arrowTop.1d8e893c711f193675bcfb11ffeb715c.svg');
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    transform: rotate(0);
  } */

  .product__thumbnails button.slick-arrow.slick-next::before {
    display: none;
  }

  .product__desc {
    padding: 0 20px 0 0;
  }

  .slick-arrow.hm__prod-prev[disabled],
  .slick-arrow.hm__prod-next[disabled] {
    opacity: 0;
  }

  .product__thumbnails button.slick-arrow.slick-prev::before {
    display: none;
  }

  .product__description-item details.product__desc p {
    letter-spacing: 0.4px;
    font: var(--sup);
    color: #5a5a5a;
    padding-right: 15px;
    margin: 0 0 10px;
  }

  .store__name__text {
    color: #ec4e1e !important;
    font-weight: 600;
    font-size: 14px;
  }

  button.slick-arrow.slick-prev.slick-disabled,
  button.slick-arrow.slick-next.slick-disabled {
    display: none !important;
  }

  /* .product__dis {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #038C19;
  } */

  /* video btn  */
  img.play_label {
    height: 50px !important;
  }

  .video-thumbnail {
    position: relative;
  }

  .video-thumbnail video {
    width: 100%;
  }

  .pdp_price_main .product__dis {
    background: var(--orange);
    color: var(--white);
    font-size: 12px;
    font-weight: 500;
    padding: 3px 7px;
    border-radius: 3px;
  }

  button.slick-arrow.slick-next.slick-disabled,
  button.slick-arrow.slick-prev.slick-disabled {
    opacity: 0.5;
  }

  /* .product__thumbnails button.slick-arrow.slick-next {
    content: '→';
    background-image: url('http://localhost:3000/static/media/arrowTop.1d8e893c711f193675bcfb11ffeb715c.svg');
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;

  } */

  /* .slick-prev:before, .slick-next:before{
    background-image:url('')
  } */

  .product__discount {
    position: absolute;
    background: #fcda28;
    padding: 11px;
    width: 100px;
    border-bottom-left-radius: 15px;
    right: -2px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 15px;
    top: -2px;
    text-align: center;
  }
  .product_APP_OFFER_discount {
    position: absolute;
    background: #4fb400;
    padding: 5px;
    bottom: 45px;
    text-align: center;
    color: white;

    border-radius: 4px;
    left: 50%; /* Center horizontally */
    transform: translateX(-50%);
    width:65%;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    align-items: center;
    gap:3px
  

  }

  .offerBanner{
    height: 100%;
  }
  .product_APP_OFFER_discount img {
  width: 18px;
  height: 18px;
      
      position: relative;

      margin: 0px;
    
  }
  .product_APP_OFFER_discount p {
    font-size: 11px;
    font-weight: 400;
    line-height: 10px;
    text-align: left;
    }

  .thumbnail {
    max-width: 200px;
    margin: 0px auto;
    width: 100%;
    height: auto;
    border: 1px solid #f5f5f5;
  }

  img.top__arrow,
  .bottom__arrow {
    height: 20px;
    width: 100%;
  }

  /* variant css starts */

  .product__variant_item {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .flavour__options,
  .size__options,
  .quantity__selector__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .size__option__item {
    display: flex;
    justify-content: start;
    gap: 10px;
    flex-wrap: wrap;
  }

  .option__value {
    padding: 10px;
    border: 0.6px solid #8d8d8d;
    border-radius: 6px;
    font-size: 14px;
    letter-spacing: 1px;
    max-height: 38px;
    min-height: 17px;
    cursor: pointer;
    font-weight: 600;
    white-space: nowrap;
  }

  .active {
    color: #ec4e1e;
    border-color: #ec4e1e;
  }
  
  .out-of-stock {
    background-color: #ddd;
    border-color: rgb(90, 89, 89);
  }

  .product__description-item details.product__desc summary .arrow-icon {
    width: 15px;
  }

  .product__description-item details.product__desc summary .arrow-icon.open {
    transform: rotate(180deg);
  }

  .product__description-item details.product__desc summary .arrow-icon.close {
    transform: rotate(0);
  }

  .imgkey {
    width: 50px;
    height: 50px;
    border: 1px solid #ddd;
  }

  .prod__img .previewPane {
    cursor: crosshair;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background-size: contain;
  }

  .prod__img #zoomer {
    display: none;
    background-repeat: no-repeat;
    border: 1px solid #ddd;
    width: 100%;
    z-index: 1000;
    left: calc(100% + 10px);
    padding: 0 0 102%;
    height: unset;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #cccccc !important;
  }

  .prod__img.desktop {
    display: block;
  }

  .prod__img.mobile,
  .prod__imgs.mobile {
    display: none;
  }

  .prod__imgs.mobile {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-image: initial;
    padding: 0;
    border-radius: 15px;
    background: rgb(247, 247, 247);
    width: 100%;
  }

  /* .prod__img img:hover .product-info {
    position: relative;
    z-index: -1;
  }
  .prod__img .previewPane:hover .product-info {
    position: relative;
    z-index: -1;
  } */

  .cursor__pointer.product-info {
    position: relative;
    z-index: -1;
  }



  .flavour__option__item {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .flavour__button {
    padding: 8px 12px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .flavour__button.active {
    border-color: #f77f00;
  }
  
  .flavour__button:hover {
    background-color: #f4a261;
    color: #fff;
  }
  

  @media (min-width: 768px) {
    .prod__img.desktop {
      display: block;
    }
    .prod__img.mobile {
      display: none;
    }

    .breadcrumb-product-name {
      display: inline-block !important;
      max-width: 400px; /* Max width constraint */
      white-space: nowrap; /* Prevent text from wrapping to a new line */
      overflow: hidden; /* Hide overflowed text */
      text-overflow: ellipsis; /* Show "..." when text overflows */
      vertical-align: bottom;
    }

    .product-image {
      position: -webkit-sticky;
      position: sticky;
      top: 160px;
    }
    .prod__img:cursor .product-info {
      position: relative;
      z-index: -1;
    }
  }
  @media (min-width: 991px) {
    .thumbnail {
      max-width: 100%;
      margin: 0px auto;
      width: 100%;
      height: 110px;
      border: 1px solid #f5f5f5;
    }

    img.play_label {
      height: 40px !important;
    }
    .product__thumbnails {
      max-width: 130px;
      min-width: 130px;
      padding: 35px 5px;
    }
  }

  @media (max-width: 990px) {
    .product_APP_OFFER_discount {
      bottom: 45x;
      width: 70%;
    }
    .product-image {
      width: 50%;
    }

    .product-info {
      width: 50%;
      padding-left: 30px;
    }

    .main__product__title h1 {
      font-size: 18px;
    }

    .pdp_icon_badge ul li p {
      font-size: 12px;
    }

    .pdp_banner_title {
      margin: 0 0 20px;
    }

    .pdp_img {
      margin: 0 0 10px;
    }
  }

  @media (max-width: 767px) {
    .product-detail {
      flex-direction: column;
      width: 100%;
      margin: 0;
    }
    .slider__item img {
      border-radius: 15px;
    }

    img.product__img--responsive {
      padding: 2px;
      border-radius: 16px;
    }
    .product__thumbnails {
      display: none !important;
    }
    .prod__img.desktop {
      display: none;
    }
    .prod__img.mobile,
    .prod__imgs.mobile {
      display: block;
      /* max-height: 340px;
        min-height: 340px; */
    }

    .product-image {
      width: 100%;
    }

    .product-info {
      width: 100%;
      padding-left: 0;
      margin: 30px 0 0;
    }

    .product__thumbnails {
      width: 100%;
      padding: 10px;
    }

    .product-detail > div {
      max-width: 100%;
    }

    .slick-slider.product__thumbnails .slick-arrow.slick-prev {
      top: auto !important;
      left: 0;
      transform: rotate(-90deg);
      bottom: 35%;
    }

    .slick-slider.product__thumbnails .slick-arrow.slick-next {
      top: 35% !important;
      left: auto;
      transform: rotate(90deg);
      right: 0px;
      bottom: auto;
    }

    .thumbnail {
      max-width: 200px;
      margin: 0px auto;
      width: 100%;
      /* height: 110px; */
      border: 1px solid #f5f5f5;
    }

    .submit__buttons__container {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px 15px;
      z-index: 10;
      margin: 0;
      background: var(--white);
      border-radius: 10px 10px 0 0;
      box-shadow: 0px -5px 10px 0px #00000040;
    }

    .btn_primary img {
      width: 18px;
    }

    .main__product__price .actual__price {
      font-size: 16px;
    }

    .prod__img #zoomer {
      display: none !important;
    }
  }

  @media (max-width: 500px) {
    .product__media__des img {
      max-width: 900px;
      display: flex;
      margin: 10px auto 0;
      border-radius: 6px;
      height: 200px;
      object-fit: contain;
    }

    .product__thumbnails .thumbnail img {
      height: 80px;
      justify-content: center;
      object-fit: contain;
    }
  
  
    .product_APP_OFFER_discount img {
    width: 16px;
    height: 16px;
      
    }
    .product_APP_OFFER_discount p {
      font-size: 10px;

      }
  }


  @media (max-width: 400px) {

    .product_APP_OFFER_discount {
      bottom: 30px;
    width: 79%;
      padding: 2px;
    }
    .product_APP_OFFER_discount img {
      width: 14px;
      height: 14px;
        
      }
      .product_APP_OFFER_discount p {
      
        font-size: 9px;
    
        }
    .main__product__price {
      gap: 10px;
    }

    .store__name a span {
      font-size: 14px;
    }

    .submit__buttons div {
      font-size: 14px;
    }

    .product__thumbnails .thumbnail img {
      height: 50px;
      justify-content: center;
      object-fit: contain;
    }
  }

