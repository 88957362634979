.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.display_flex {
    display: flex;
    justify-content: space-between;
}

.account__wrapper {
    width: 100%;
}

.account__menu__item img {
    height: 18px;
    width: 18px;
}

.modal .account__right__content {

    box-shadow: none;
    padding: 0px;
}


.account__left__content,
.account__right__content {
    border-radius: 15px;
    height: 100%
}

.account__right__content {
    flex: 1;
}

.account__left__content {
    box-shadow: 0px 0px 5px 1px #00000033;
    width: 30%;
    max-width: 300px;
    padding: 20px 0;
    overflow: hidden;
}

.account__menu__item.active {
    background: var(--orange);
    color: #fff;
}

.account__menu__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding: 15px 20px;
    cursor: pointer;
    font: var(--h6);
    font-weight: 400;
    position: relative;
}


.account__menu__item svg {
    height: 20px;
    width: 20px;
}

.account__menu__item.active svg path {
    fill: #ffffff;
}

.account__edit__btn {
    background: var(--orange);
    color: var(--white);
    padding: 10px 30px;
    border-radius: 6px;
    cursor: pointer;
    margin: 0 auto;
    width: fit-content;
    font: var(--sup);
}

select.select__gender {
    border-radius: 6px;
}

.select__gender {
    position: relative;
    display: inline-block;
    width: 200px;
}

.select__gender select {
    display: none;
    /* Hide the default select */
}

.select__gender__selected {
    background-color: #fff;
    border: 1px solid #8D8D8D;
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.select__gender__options {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 100%;
    z-index: 1;
}

.select__gender__option {
    padding: 10px;
    cursor: pointer;
}

.select__gender__option:hover {
    background-color: var(--orange);
    color: #fff;
}


.account__menu__item svg path {
    fill: #000;
}

.account__main__heading {
    padding: 0px 15px 15px;
    text-align: center;
    font: var(--h5);
}

.account__main__menu {
    display: flex;
    flex-direction: column;
}


.breadcrumbs {
    padding: 30px 0;
    font: var(--sup);
}

.account__main__container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 35px;
    margin: 0 0 60px;
}

.account__container {
    padding: 20px;
}

.modal .account__container {
    padding: 0px;
}

.account__phone__head {
    font: var(--sup);
    display: block;
    padding: 0 0 4px;
}

.account__phone__box input,
.account__phone__box select {
    width: 100%;
    padding: 7px 10px;
    font: var(--sup);
    border: 1px solid #8D8D8D;
    color: var(--black);
}

.account__form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.account__phone__box {
    width: 50%;
    padding: 0 10px 15px;
}

.address__main__heading {
    font: var(--h5);
}

.product__thumbnails .slick-slide.slick-active.slick-current .thumbnail {
    border: 2px solid var(--orange);
    border-radius: 6px;
    overflow: hidden;
}

.account__right__content .account_card_right {
    box-shadow: 0px 0px 5px 1px #00000033;
    border-radius: 15px;
    padding: 20px;
}

.product__thumbnails .thumbnail {

    cursor: pointer;
}

.user__profile__main {
    display: flex;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 16px;
    flex-direction: column;
}

.user__profile__main-item {
    display: flex;
    flex-direction: column;
    gap: 30px;
    box-shadow: 0px 0px 5px 1px #00000033;
    border-radius: 15px;
    padding: 20px;
}

.user__profile__main-item .user__width {
    text-align: left;
    font: var(--h5);
}

.user__profile__main-item .user__width label {
    max-width: 150px;
    width: 100%;
    color: var(--black);
}

.user__profile__main-item .user__width>div {
    color: #5A5A5A;
}

.edit__profile__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px;
}

.user__profile__head {
    font: var(--h4);
}

.user__profile__main .account__edit__btn {
    margin: 0;
    font-weight: 100;
}

.hide {
    display: none !important;
}

.user__profile__data {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.user__width {
    display: flex;
    width: 100%;
}

.account__right__content .account_page_title .close {
    display: none;
}

.account_page_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;
}

.modalContent .account__form {
    margin: 0 0 15px;
}

.modalContent .account__form .account__phone__box {
    width: 100%;
    padding: 0 0px 10px;
}

.modalContent .address__info {
    width: 100%;
}

.modalContent .account__right__content .account_card_right {
    padding: 0;
    box-shadow: none;
}

.modalContent .account__main__container {
    margin: 0;
}

.wishlist-wrapper{
margin-top: 20px;
display: grid;
grid-template-columns: 1fr 1fr ;
    border-radius: 20px;
    padding: 15px;
    height: 100%;
    box-shadow: 0px 0px 5px 1px #00000033;
gap: 20px 10px;
}




@media(min-width:600px) {
    .modal .account__right__content {
        gap: 0px;
    }
}

@media(min-width:1024px) {
    .modal .account__right__content {
        padding: 0px;
    }
    .wishlist-wrapper{

    
        grid-template-columns: 1fr 1fr 1fr;}
}

@media(max-width:767px) {
    .account__main__container {
        flex-direction: column;
    }

    
    .account__left__content {
        width: 100%;
        max-width: 400px;
    }

    .account_card_right .account__phone__box {
        width: 100%;
    }

    .address__main__heading h5 {
        font-size: 16px;
    }

    .breadcrumbs {
        padding: 20px 0;
    }
}

@media(max-width:430px) {
    select.select__gender {
        width: 100%;
    }
    .account__right__content{
        width: 100%;
    }
    .wishlist-wrapper{
width: 100%;
    
        grid-template-columns: 1fr ;}
}

/* @media(max-width:320px) {
    .account__phone__box select {
        max-width: 238px;
    }
} */