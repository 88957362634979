.hm__slider__wrapper {
  padding: 25px 0;
}

/* src/components/bannerSlider/homeSlider/hmSlider.css */
.hm__slider__wrapper .slick-dots {
  bottom: -10px;
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.hm__slider__wrapper .slick-dots li {
  width: 8px;
  height: 8px;
  background: #EC4E1E;
  border-radius: 50%;
  margin: 0 5px !important;
  opacity: 0.25;
}

.hm__slider__wrapper .slick-dots li.slick-active {
  opacity: 1;
}

.hm__slider__wrapper .slick-dots li button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.hm__slider__wrapper .slick-dots li button:before {
  content: '';

}
.desktop__video{
  width: 100%;

}
.hm__slider__wrapper .slick-dots li.slick-active button:before {
  background: #EC4E1E;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.hm__slide__item img {
  border-radius: 20px;
  cursor:pointer;
}
.hm__slide__item video {
  border-radius: 20px;
  cursor:pointer;
}

.hm__slide__item:focus-visible {
  outline: none;
}

.hm__slider__container.page-width.padding-lr20,
.hm__product-container {
  position: relative;
}
@media(min-width:768px){
  .desktop__video{
    width: 100%;
    max-height: 350px;
  }
 }

 
 