
.store-list-container{
	align-items: flex-start !important;
	padding: 40px 10px !important;
	display: flex;
	gap: 20px;
	
}

.left-contanier{
	width: 40%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;

	
}
.left-contanier p {

font-size: 16px;
font-weight: 400;
line-height: 21.76px;

color: #5A5A5A;


}
.left-contanier select{
	height: 35px;
	width: 300px;
	border-radius: 30px;
	padding: 5px 10px;
	font-weight: 500;
}
.left-contanier option{
	font-weight: 500;
}

.left-contanier h3{
	
font-size: 36px;
font-weight: 500;
line-height: 48.96px;
text-align: left;
color: #3E4958;

text-decoration-skip-ink: none;

}
.google-map-container{
	width: 100%;
	height: 100%;
}
.google-maps-contanier{
	width: 60%;
	height: 555px;
	border: none;
	border-radius: 30px;
	overflow: hidden;   
	
}
.store-pin{
	width: 75px;
	height: 45px;

	object-fit: cover; 
	display: inline-block;
}
.store-box{
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin: 5px 0px;
	height:445px;
    overflow: auto;
	padding: 5px;
}
.store-box-container{
	width: 100%;
	/* height: 160px; */
	
	border-radius: 18px;
	box-shadow: 0px 1px 4px 1.5px #00000029;
display: flex;
flex-direction:column;
padding: 8px;
gap: 8px;
}

.store-image{
	
	width: 35%;
	
	border-radius: 10px;
	object-fit: contain;
}
.store-details{
	display: flex;
	gap: 10px;
}
.store-detail{
	width: 60%;
	
	
	display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.store-detail h5 {
	color: #333C44;

font-size: 18px;
font-weight: 600;
line-height: 18.64px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.store-detail p{

    font-size: 14px;
    font-weight: 400;
    line-height: 15.4px;
text-align: left;

text-decoration-skip-ink: none;


}
.store-header{
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
}
.rating-star{
	display: flex;
	align-items: center;
}
.store-hours {
	display: flex;
	align-items: center;
} 
.store-hours p{
	color: #3E4958;

}
.p2{

font-size: 15px;
font-weight: 500;
line-height: 20.4px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #4FB400;


}

.btn-container{
	height: 30px;
	width: 100%;
	
	display: flex;
	gap: 10px;

}
.btn-container button{
	height: 100%;
	width: 50%;
	border: none;
} 

.detail-contanier{
	width: 100%;
	
	padding: 20px !important;
	display: flex;
	gap: 20px;
	flex-direction: column;
}
.border{
	border: 1px solid green;
}

.store-title-wrapper{
	width: 100%;
	
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.product-wrapper{
	width: 100%;

}
.payments-wrapper{
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;

}

.rating-box{
	display: flex;
	align-items: center;

}

.product-container{
	width: 100%;
	height: 90%;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	padding: 10px;
	
}

.product-box{
	height: 120px;
	width: 120px;
	box-shadow: 0px 1px 4px 0px #00000029;
	padding: 10px;
color: #333C44;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;

}
.payment-card-wrapper{
	display: grid;
    grid-template-columns: 1fr 1fr 1fr;
	gap:10px;
	
	
}
.desktop-img{
	display: block !important;
	width: 100%;
}
.product_atc_btn{
	padding: 5px !important;
	
	gap: 8px !important;

}
.btn-container .product_atc_btn img {
	height: 18px;
    
	object-fit: contain;

}
@media (max-width: 1024px) {

	.store-list-container {
		align-items: flex-start !important;
		padding: 40px 10px !important;
		display: flex
	;
		gap: 10px;}
	
	.store-detail h5 {
	
	
	font-size: 18px;
	font-weight: 600;
	line-height: 18px;

	
	}
	.store-detail p{
	
		font-size: 11px;
		font-weight: 400;
		line-height: 13.4px;

	
	}
	.p2{
	
		font-size: 12px;
		font-weight: 500;
		line-height: 13.4px;

	
	}
	.store-box-container {
		
		/* height: 160px; */
		border-radius: 14px;
		
		padding: 7px;
		gap: 6px;
	}
	.store-image {
		/* height: 150px */
	}
	.store-box-container {
		height: auto;
	}

	.store-detail {
		height: auto;
	}
.btn-container button {
	padding: 5px !important;
	font-size: 14px;
}

.google-maps-contanier {
	width: 60%;
	height: 600px;
}
}
@media (max-width: 768px) {


	.store-image {
		/* height: 175px */
	}
	.store-list-container{
		flex-direction: column-reverse;
	}


	.left-contanier {
		width: 100%;
	}
	.store-box {
		height: auto;
		gap: 22px;
	}
	.store-detail h5 {
		font-size: 24px;
		line-height: 22.64px;

		}
		.store-detail p{
	
			font-size: 13px;
			font-weight: 400;
			line-height: 16.4px;
	
		
		}
		.btn-container .product_atc_btn img {
			height: 15px;
			width: 15px;
		}
		.p2{
	
			font-size: 14px;
			font-weight: 500;
			line-height: 16.4px;
	
		
		}
		.btn-container button {
			padding: 15px !important;
			font-size: 17px;
		}
		.btn-container {
			height: 33px;
		}

		.store-list-container{
			padding: 40px 20px !important;
	
		}
		.google-maps-contanier {
			width: 100%;
			height: 400px;
		}
}
@media (max-width: 425px) {

	.store-list-container{
		padding: 40px 10px !important;

	}

	
	.google-maps-contanier {
		width: 100%;
		height: 300px;
	}
	.google-map-container {
		height: 100%;
		width: 100%;
	}
	.store-detail h5 {
	
	
	font-size: 21px;
	font-weight: 600;
	line-height: 21.64px;

	
	}
	.store-detail p{
	
		font-size: 12px;
		font-weight: 400;
		line-height: 13.4px;

	
	}
	.p2{
	
		font-size: 12px;
		font-weight: 500;
		line-height: 13.4px;

	
	}
	.store-image {
		height: 38%;
	}
	.store-box-container {
		height: auto;
	}

	.store-detail {
		height: auto;
	}
.btn-container button {
	padding: 5px !important;
	font-size: 14px;
}
.btn-container {
	height: 25px;
	
}
.store-box-container{
	gap: 5px;
	padding: 5px;
	border-radius: 12px;
}
.store-box {
	height: auto;
	gap: 10px;
}
}
@media (max-width: 375px) {

	.btn-container {
		height: 20px;
		
	}
	.store-details{
		gap: 5px;
	}
	


	.left-contanier h3{
		font-size: 23px;
    font-weight: 500;
	}
	.store-detail h5 {
	
	
	font-size: 15px;
	font-weight: 600;
	line-height: 16.64px;

	
	}
	.store-detail p{
	
        font-size: 11px;
        font-weight: 400;
        line-height: 11px;
	
	}
	.p2{
	
		font-size: 9px;
		font-weight: 500;
		line-height: 11px;

	
	}
	
.btn-container button {
	
	font-size: 12px;
}




	
}

@media (max-width: 320px) {
	.btn-container button {
        font-size: 11px;
    }
	.store-detail h5 {
	
	
		font-size: 15px;
		font-weight: 600;
		line-height: 16.64px;
	
		
		}
		.store-detail p{
		
			font-size: 10px;
			font-weight: 400;
			line-height: 10px;
		
		}
		.p2{
		
			font-size: 9px;
			font-weight: 500;
			line-height: 11px;
	
		
		}
}
