/* .hm__product-container.section_space.bolt_nutritions.hm__prod__slider.extra-slider-container.page-width {
    margin: 10px auto;
    width: 100%;
  } */


.hm__product-container.section_space.bolt_nutritions.hm__prod__slider {
height: 454px;
z-index: 1;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
  justify-content: center;
  background-size: contain;
  box-sizing: border-box;
  width: 100%;
  
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  /* margin: 50px auto; */
  display: flex
;
  align-items: center;
  justify-content: center;
}

.extra-slider-container .slick-track{
width: 70%;
height: 70%;
}

.bolt_nutri_slide .slick-track {
  display: flex;
  padding: 0;
  justify-content: center;
}

.extra-slider-container .card-wrapper{
  height: 100%;
}

.extra-slider-container .card-item {
  width: 100%;
  height: 80%;
}

.extra-slider-container .slick-next {
    right: -20px !important ;
  }

  .extra-slider-container .slick-prev {
    left: -20px !important ;
  }

.slick-slide.slick-active.slick-current .product-card {
    padding: 0;
    margin: 0 !important;
}

.bolt_nutri_slide .slick-track {
    padding: 0;
}

.bolt_nutri_slide .product-n {
    font-size: 12px;
}

.bolt_nutri_slide .product_price .price {
    font-size: 2px;
}

.bolt_nutri_slide .card-content {
    margin: 0;}

.extra-slider-container .card-content {
        font-size: 0.85rem;
        line-height: 1.2;
     }

  .extra-slider-container .product_title .product-n {
    font-size: 0.8rem; 
    margin: 0;
   
  }
  
  .extra-slider-container .product_price .actual_price,
  .extra-slider-container .product_price .compare_price,
  .extra-slider-container .product_price .product__discounts {
    font-size: 13px !important; 
  }
  .hm__product-container.section_space.bolt_nutritions.hm__prod__slider .card-content .product_title {
   
    min-height: 58px;
}
.card-content {
  padding: 0 12px 12px;}

  .extra-slider-container .product_price {
    margin: 0;
    padding: 2px;
  }
  
  .extra-slider-container .card-image {
    width: 0%;
    margin: 0; /* Remove extra spacing */
    padding: 37%; /* Remove extra spacing */
    display: flex;
    justify-content: center; 
    align-items: center;
}

  
 .extra-slider-container .card-item {
  border-radius: 20px;
  width: 100%;
  height: 100%;

    }


   .extra__card__item {
        width: 70%;
        height: 70%;
        right: 0px; 
      top: 30%; }

        .product_btns {
          margin-top: 10px;
      }

  
     /* Adjustments for Mobile */
@media (max-width: 767px) {

  .hm__product-container.section_space.bolt_nutritions.hm__prod__slider.extra-slider-container.page-width {
    margin: 10px auto !important;
    width: 100%;
  }
  
  .hm__product-container.section_space.bolt_nutritions.hm__prod__slider {

    }

 .extra-slider-container .card-content {
    font-size: 0.75rem;
  }

  .extra-slider-container .extra__card__item {
    width: 98%; 
    height: 50% !important; 
bottom: 2px;
top: auto;

  }
  .extra-slider-container .product_title .product-n {
    font-size: 1.1rem;
    margin: 0;
}

  .extra-slider-container .product_price .actual_price,
  .extra-slider-container .product_price .compare_price,
  .extra-slider-container .product_price .product__discounts {
    font-size: 18px !important;
  }

  .extra-slider-container .slick-prev,
  .extra-slider-container .slick-next {
    display: none !important;
  }
}

/* Tablet Adjustments */


/* Desktop Adjustments */
/* @media (min-width: 1440px) {

  .hm__product-container.section_space {
    padding: 40px;
  }

  .extra-slider-container .card-content {
    font-size: 1rem;
  }

  .extra-slider-container .product_price .actual_price,
  .extra-slider-container .product_price .compare_price {
    font-size: 14px !important;
  }

  
} */

@media (max-width: 1222px) and (min-width: 1024px) {

  .hm__product-container.section_space.bolt_nutritions.hm__prod__slider {
    
    height: 100%;
    min-height: 450px;
    margin: 0px;
    }
    .extra-slider-container .card-image {
      padding: 40%;
    }
    .card-content {
      padding: 0 8px 10px;
  }
  .hm__product-container.section_space.bolt_nutritions.hm__prod__slider .card-content .product_title {
    min-height: 49px;
 
  }
  
.product_btns {
  margin-top: 5px;
}

}
@media (max-width: 1024px) and (min-width: 768px) {
  .hm__product-container.section_space.bolt_nutritions.hm__prod__slider {
    
    height: 100%;
    min-height: 353px;
    margin: 0px;
    }
  .extra-slider-container .card-image {
    padding: 28%;
  }
  .card-content {
    padding: 0 8px 10px;
}
.product_btns {
  margin-top: 7px;
}

}
@media (min-width: 768px) and (max-width: 1024px) {

  .hm__product-container.section_space.bolt_nutritions.hm__prod__slider {
    
    }

    /* .extra-slider-container .slick-track{
      width: 70% !important;
      height: 90% !important;
      } */

    .hm__product-container.section_space.bolt_nutritions.hm__prod__slider.extra-slider-container.page-width {
      margin: 0px !important;
      width: 100%;
    }

    .extra-slider-container .slick-track {
     height: 50%;
      
    }


    .extra-slider-container .card-image {
      
      padding: 28%; /* Remove extra spacing */
  }
  
  
    .extra-slider-container .extra__card__item {
      width: 70%; 
      height: 70%; 
  
    } 

  .extra-slider-container .card-content {
    font-size: 0.8rem;
  }

  .extra-slider-container .product_price .actual_price,
  .extra-slider-container .product_price .compare_price {
    font-size: 12px !important;
  }}
  @media (min-width: 768px) and (max-width: 850px) {
    .product_btns {
      margin-top: 0px;
    }
    .hm__product-container.section_space.bolt_nutritions.hm__prod__slider .card-content .product_title {
      min-height: 40px;
   
    }
    
    .extra__card__item{
      top: 24%!important;

    }
  }
  @media (min-width: 850px) and (max-width: 1024px) {
    .product_btns {
      margin-top: 1px;
    }
    .hm__product-container.section_space.bolt_nutritions.hm__prod__slider .card-content .product_title {
      min-height: 40px;
   
    }
    .extra__card__item{
      top: 30%!important;

    }
  }
  @media (min-width: 667px) and (max-width: 767px) {
    .hm__product-container.section_space.bolt_nutritions.hm__prod__slider {
      height: 1000px;
      margin: 0px;
    }
   
   
  }
  @media (min-width: 567px) and (max-width: 667px) {
    .hm__product-container.section_space.bolt_nutritions.hm__prod__slider {
      height: 900px;
      margin: 0px;
    }
   
   
  }
  @media (min-width: 425px) and (max-width: 567px) {
    .hm__product-container.section_space.bolt_nutritions.hm__prod__slider {
      height: 760px;
      margin: 0px;
    }
   
   
  }

  
  @media (max-width: 600px) {
    .hm__product-container.section_space.bolt_nutritions.hm__prod__slider {
    
      margin: 0px;
    }
    .extra-slider-container .product_title .product-n {
      font-size: 0.9rem;
      margin: 0;
  }
  .extra-slider-container .product_price .actual_price, .extra-slider-container .product_price .compare_price, .extra-slider-container .product_price .product__discounts {
    font-size: 15px !important;
}
.hm__product-container.section_space.bolt_nutritions.hm__prod__slider .card-content .product_title {
  min-height: 46px;
}
  }

  @media (min-width: 375px) and (max-width: 425px) {
    .hm__product-container.section_space.bolt_nutritions.hm__prod__slider {
      height: 570px;
    }
    .hm__product-container.section_space.bolt_nutritions.hm__prod__slider .card-content .product_title {
      min-height: 35px;
  }
  .extra-slider-container .extra__card__item {
    bottom: 20px;
  }
   
  }

  @media (min-width: 320px) and (max-width: 375px) {
    .hm__product-container.section_space.bolt_nutritions.hm__prod__slider {
      height: 470px;
    }
    .hm__product-container.section_space.bolt_nutritions.hm__prod__slider .card-content .product_title {
      min-height: 35px;
  }
  .extra-slider-container .extra__card__item {
    bottom: 40px;
  }
   
  }